@keyframes appear {
    0% {
        margin-top: 16px;
        opacity: 0;
    }
    100% {
        margin-top: 0px;
        opacity: 1;
    }
}

.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}

.react-grid-item {
    opacity: 0;
    animation: 0.4s ease-out 0s 1 appear forwards;
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item img {
    pointer-events: none;
    user-select: none;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
    animation: none;
    overflow: hidden;
    border-radius: 2px;
    background: rgb(0, 140, 255);
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(255, 255, 255, 0.4);
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
    display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

.data-widget .ant-card {
    overflow: hidden;
    height: 100%;
}

.data-widget .ant-card {
    height: 100%;
}

.data-widget.blink .ant-statistic-content {
    animation: blinker 1s linear infinite;
}

.data-widget.amber .ant-card {
    background-color: #ffe7ba;
    border-color: #ffd591;
}

.data-widget.amber .ant-statistic-title {
    color: #fa8c16;
}

.data-widget.amber .ant-statistic-content {
    color: #fa8c16;
}

.data-widget.red .ant-card {
    background-color: #ffccc7;
    border-color: #ffa39e;
}

.data-widget.red .ant-statistic-title {
    color: #f5222d;
}

.data-widget.red .ant-statistic-content {
    color: #f5222d;
}

.blink .g2-html-annotation {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.ant-table,
.ant-table table,
.ant-table-row {
    height: 100%;
}

.ant-table-title {
    text-align: center;
}

.ant-table-cell {
    height: inherit !important;
    padding: 0 !important;
    margin: 4px !important;
    text-align: center !important;
}

.ring-icon {
    animation: ring 1s ease-in-out infinite;
}

@keyframes ring {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}
